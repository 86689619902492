import { useContext } from "react";
import { UmbracoContext } from "../contexts/Umbraco";
import A from "~/components/A";
import "./Footer.css";
import RichText from "./umbraco/RichText";
const Footer = () => {
    const { data } = useContext(UmbracoContext);
    let properties = data?.properties;
    let logo = properties?.footerLogo ? properties?.footerLogo[0] : null;
    let footerTextCol2 = properties?.footerTextCol2;
    let footerTextCol3 = properties?.footerTextCol3;
    return (
        <footer className="footer">
            <div className="container footer__inner">
                <A href="/" className="footer__logo" title="Acron logo">
                    <img
                        alt="Acron logo"
                        src={logo?.url}
                        width="177.196px"
                        height="53.251px"
                    />
                </A>
                <div><RichText>{footerTextCol2?.markup}</RichText></div>
                <div><RichText>{footerTextCol3?.markup}</RichText></div>
            </div>
        </footer>
    );
};

export default Footer;