import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { UmbracoProvider } from "~/contexts/Umbraco";
// import CustomScrollbar from "~/components/CustomScrollbar";

const rootDiv = document.getElementById('root');

ReactDOM.createRoot(rootDiv).render(
  <UmbracoProvider dataset={rootDiv.dataset}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </UmbracoProvider>,
);
