import { Link } from "react-router-dom";

const A = ({ href, children, underline, ...props }) => {
    if (href?.startsWith("/") || href?.startsWith("#")) {
        return (<Link to={href} {...props} style={{ textDecoration: underline ? "underline" : null, ...props.style }}  >{children}</Link>);
    }
    else {
        return (<a href={href} {...props} style={{ textDecoration: underline ? "underline" : null, ...props.style }} target="_blank">{children}</a>);
    }
};

export default A;