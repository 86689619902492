import parse from 'html-react-parser';

const RichText = ({ topLevel, children, className = "", ...props }) => {
    return (
        <div className={` ${topLevel ? "container" : ""} ${className}`} {...props}>
            {typeof children === "string" ? parse(children) : null}
        </div>
    );
};

export default RichText;