
import { createContext, useCallback, useEffect, useLayoutEffect, useState } from "react";

export const UmbracoContext = createContext();

export const UmbracoProvider = ({ children, dataset }) => {
    const [nodeId, setNodeId] = useState(null);
    const [nodeGuid, setNodeGuid] = useState(null);

    const [currentRoute, setCurrentRoute] = useState(null);
    const [routes, setRoutes] = useState(null);
    const [data, setData] = useState(null);
    const [dataError, setDataError] = useState(null);

    useEffect(() => {
        if (dataset) {
            setNodeId(dataset.nodeId);
            setNodeGuid(dataset.nodeGuid);
        }
    }, [dataset]);

    useLayoutEffect(() => {
        if (nodeId) {
            let culture = "nb-NO";
            // /headless/pagerouting/1063/nb-NO
            fetch(`/headless/pagerouting/${nodeId}/${culture}`).then(r => r.json()).then(json => {
                setRoutes(json);
                // console.log(json);
            }).catch(err => {
                console.error(err);
            });
        }
    }, [nodeId]);

    const fetchUmbracoItem = useCallback(
        (guid, setState, setError) => {
            fetch(`/umbraco/delivery/api/v1/content/item/${guid}`)
                .then(res => {
                    if (res.status !== 404) {
                        return res.json();
                    } else {
                        throw "Id does not exist";
                    }
                })
                .then(json => {
                    // console.log(json);
                    if (typeof setState === "function") setState(json);
                })
                .catch(err => {
                    console.error(err);
                    if (typeof setError === "function") setError(err);
                });
        },
        [],
    );

    useLayoutEffect(() => {
        if (nodeGuid) {
            // fetch(`/umbraco/delivery/api/v1/content/?fetch=descendants:${nodeGuid}`)
            //     .then(res => {
            //         if (res.status !== 404) {
            //             return res.json();
            //         } else {
            //             throw "Id does not exist";
            //         }
            //     })
            //     .then(json => {
            //         console.log(json);
            //     })
            //     .catch(err => {
            //         console.error(err);
            //         setDataError(err);
            //     });
            fetchUmbracoItem(nodeGuid, setData, setDataError);
        }
    }, [nodeGuid]);

    const getUmbracoLink = useCallback(
        (link) => {
            link = Array.isArray(link) ? link[0] : link;
            return (link?.url || link?.route?.path) + (link?.queryString || "");
        },
        [],
    );

    const [pageBgOverride, setPageBgOverride] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [pageBg, setPageBg] = useState(pageBgOverride || currentPage?.properties?.pageBg || data?.properties?.rootBg);
    const [hasOverride, setHasOverride] = useState(null);

    useEffect(() => {
        if (pageBgOverride || currentPage?.properties?.pageBg) {
            setHasOverride(true);
        }
        else setHasOverride(false);
        setPageBg(pageBgOverride || currentPage?.properties?.pageBg || data?.properties?.rootBg);
    }, [pageBgOverride, currentPage, data]);

    return (
        <UmbracoContext.Provider
            value={{
                // DOM
                nodeId,

                // Umbraco data
                data,
                dataError,
                fetchUmbracoItem,

                // Routes
                routes,
                currentRoute,
                setCurrentRoute,
                currentPage,
                setCurrentPage,

                // Utils
                getUmbracoLink,

                // Page,
                pageBg,
                setPageBgOverride,
                hasOverride
            }}>

            {children}
        </UmbracoContext.Provider>
    );
};