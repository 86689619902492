import { lazy, useContext, useEffect } from 'react';

import Fallback from "~/components/Fallback";
import { UmbracoContext } from '../contexts/Umbraco';
import { useLocation } from 'react-router-dom';
import PageBg from "~/components/PageBg";
const Root = lazy(() => import('~/pages/Root'));
const Page = lazy(() => import('~/pages/Page'));

const Wrapper = ({ children }) => {
    const { data, pageBg, hasOverride } = useContext(UmbracoContext);
    let properties = data?.properties;
    let useVideoBg = properties?.useVideoBg;
    const { pathname } = useLocation();
    return (
        <>
            <PageBg
                backgroundImage={pageBg}
                style={{ filter: properties?.rootBgCSSFilter }}
                isRoot={pathname === "/"}

                videoSrc={!hasOverride && useVideoBg ? properties?.rootBgVideo : undefined}
                // key={(Array.isArray(pageBg) ? pageBg[0]?.id : pageBg?.id) + "page-bg-wrapper"}
                endOpacity={pathname === "/" ? properties.rootBgOpacity : properties.pageBgOpacity}
                fadeBottomOpacity={properties.rootFadeBottomOpacity}
            />
            {children}
        </>
    );
};

const Switcher = ({ route }) => {
    let type = route?.alias?.toLowerCase();

    switch (type) {
        case "root":
            return <Fallback><Root /></Fallback>;
        case "page":
            return <Fallback><Page route={route} /></Fallback>;
        default:
            return <pre>{JSON.stringify(route, null, 1)}</pre>;
    }
};

const PageSwitcher = ({ route }) => {
    const { setCurrentRoute, data } = useContext(UmbracoContext);

    useEffect(() => {
        setCurrentRoute(route);
    }, [route]);
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname === "/") {
            document.title = data.name;
        } else {
            document.title = data.name + " - " + route.name;
        }
    }, [pathname]);

    return (
        <Wrapper>
            <Switcher route={route} />
        </Wrapper>
    );
};

export default PageSwitcher;