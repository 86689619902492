

import { useContext, useEffect } from "react";
import {
  Routes, Route, useLocation
} from "react-router-dom";

// Internal components
import Fallback from '~/components/Fallback';
import AllHotkeys from '~/components/AllHotkeys';

// Contexts
import { UmbracoContext } from "./contexts/Umbraco";

// Pages
import Layout from "./Layout";
import PageSwitcher from "~/pages/PageSwitcher";
// import AnimatedCursor from "react-animated-cursor";

function App() {
  const { routes, data, setPageBgOverride } = useContext(UmbracoContext);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    setPageBgOverride(null);
  }, [pathname]);

  return (
    <>
      {/* <AnimatedCursor
        innerSize={8}
        outerSize={30}
        color='255,255,255'
        outerAlpha={0.3}
        innerScale={0.8}
        outerScale={2}
        trailingSpeed={7} // Higher is slower
        // hasBlendMode={true}
        outerStyle={{
          // mixBlendMode: 'exclusion',
          // backgroundColor: "var(--orange)",
          // backgroundColor: "transparent",
          // border: "1px solid #fff"
        }}
      /> */}
      <AllHotkeys />
      <Layout>
        <Fallback>
          {data ?
            <Routes>
              {routes?.map(r => <Route path={r.url} key={r.key + "route"} element={<PageSwitcher route={r} />} />)}
            </Routes>
            : <div className="container"><center>Laster inn..</center></div>}
        </Fallback>
      </Layout>
    </>
  );
}

export default App;
