
// External components
import Hotkeys from "react-hot-keys";

// Contexts
import { useContext } from "react";
import { UmbracoContext } from "../contexts/Umbraco";

const AllHotkeys = () => {
    const { currentRoute, nodeId } = useContext(UmbracoContext);
    let rootNode = nodeId;
    return (
        <>
            <Hotkeys
                keyName="alt+shift+g"
                allowRepeat={true}
                onKeyDown={() =>
                    window
                        .open(
                            `https://github.com/aksellsor/aks_react_acron`,
                            "_blank"
                        )
                        .focus()
                }
            />
            {(currentRoute || rootNode) && (
                <Hotkeys
                    keyName="alt+shift+u"
                    allowRepeat={true}
                    onKeyDown={() => {
                        window
                            .open(
                                `${currentRoute || rootNode
                                    ? `https://1235738-www.web.tornado-node.net/umbraco#/content/content/edit/${currentRoute?.id || rootNode
                                    }`
                                    : "https://1235738-www.web.tornado-node.net/umbraco"
                                }`,
                                "_blank"
                            )
                            .focus();
                    }
                    }
                />
            )}
        </>
    );
};

export default AllHotkeys;