import React, { useContext } from 'react';
import { useLocation } from "react-router";
import "./Nav.css";
import A from "~/components/A";

// External components
import Hamburger from 'hamburger-react';
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { UmbracoContext } from '../contexts/Umbraco';
function Sticky({
    offset,
    stuckClasses = "",
    unstuckClasses = "",
    children,
    className
}) {
    const [stuck, setStuck] = useState(false);
    const ref = React.createRef();

    const classes = stuck ? stuckClasses : unstuckClasses;
    className += ` ${classes}`;
    const inlineStyles = {
        position: "sticky",
        top: offset
    };

    useEffect(() => {
        const cachedRef = ref.current;
        const observer = new IntersectionObserver(
            ([e]) => setStuck(e.intersectionRatio < 1),
            { threshold: [1] }
        );
        observer.observe(cachedRef);
        return () => observer.unobserve(cachedRef);
    }, [ref]);

    return (
        <div style={inlineStyles} className={className} ref={ref}>
            {children}
        </div>
    );
}
const NavItem = ({ children, href, icon, className, ...props }) => {
    const { pathname } = useLocation();
    const listItem = {
        hidden: { opacity: 0, y: 15 },
        show: { opacity: 1, y: 0 }
    };
    return (
        <motion.li variants={listItem} whileTap={{ scale: icon ? 0.9 : 1 }} whileHover={{ scale: icon ? 1.1 : 1 }} className={`nav__menu__item ${icon ? "nav__menu__item--icon" : ""}`}><A className={`nav__menu__item__link ${pathname === href ? "current" : ""} ${className}`} href={href} {...props}>{children}</A></motion.li>
    );
};
const Nav = () => {
    const { width } = useWindowDimensions();

    const [isOpen, setOpen] = useState(false);
    useEffect(() => {
        if (width > 1000) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [width]);

    useEffect(() => {
        document.body.classList.toggle("noscroll", isOpen && width <= 1000);
    }, [isOpen, width]);
    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.05
            }
        }
    };
    const { data } = useContext(UmbracoContext);
    let properties = data?.properties;
    let logo = properties?.headerLogo ? properties?.headerLogo[0] : null;
    let navMenu = properties?.navMenu;
    return (
        <Sticky
            offset="0" // top, right, bottom, left
            className="nav-wrapper"
            stuckClasses="stuck"
        // unstuckClasses="baz qux"
        // stuckStyles={{ color: "red" }}
        // unstuckStyles={{ color: "blue" }}
        >
            {/* <pre>{JSON.stringify(navMenu, null, 1)}</pre> */}
            <nav className='nav container' >
                {/* <pre>{JSON.stringify(logo, null, 1)}</pre> */}
                <A href="/" className="nav__logo" title="Acron logo">
                    <img
                        src={logo?.url}
                        width="177.196px"
                        alt="Acron logo"
                        height="53.251px"
                        decoding="async"
                        fetchpriority="high"
                    />
                </A>
                <motion.div whileTap={{ scale: 0.95 }} className="nav__hamburger" onClick={() => setOpen(b => !b)}><Hamburger toggled={isOpen} direction="left" size={30} title="Open menu" /></motion.div>
                <AnimatePresence mode='wait'>
                    {isOpen && (
                        <motion.ul variants={container} initial="hidden" exit="hidden" animate="show" className={`nav__menu `} key={`nav__menu-${isOpen}`}
                            onClick={() => {
                                if (width <= 1000) {
                                    setOpen(false);
                                }
                            }}>
                            {navMenu?.map(item => <NavItem href={item?.url || item?.route?.path} key={"navitem" + item.destinationId} title={item.title}>{item.title}</NavItem>)}
                            <hr className='nav__hr' aria-hidden="true" />
                            {properties?.linkedInUrl?.length > 0 && <NavItem href={properties.linkedInUrl} key={"navitem" + "linkedin"} title="Besøk vår LinkedIn" icon={true}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 44.137 44.137">
                                    <path id="Icon_metro-linkedin" data-name="Icon metro-linkedin" d="M39.293,1.928H9.984A7.435,7.435,0,0,0,2.571,9.341V38.651a7.435,7.435,0,0,0,7.413,7.413H39.293a7.435,7.435,0,0,0,7.413-7.413V9.341a7.435,7.435,0,0,0-7.413-7.413ZM19.122,37.788H13.6V18.479h5.517V37.788ZM16.363,15.721a2.758,2.758,0,1,1,2.758-2.758A2.759,2.759,0,0,1,16.363,15.721ZM38.431,37.788H32.914V26.755a2.758,2.758,0,1,0-5.517,0V37.788H21.88V18.479H27.4V21.9c1.138-1.563,2.877-3.425,4.827-3.425,3.428,0,6.207,3.088,6.207,6.9V37.788Z" transform="translate(-2.57 -1.928)" fill="#fff" />
                                </svg>
                            </NavItem>}
                        </motion.ul>
                    )}
                </AnimatePresence>
            </nav>
        </Sticky>
    );
};

export default Nav;