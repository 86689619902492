import { lazy } from 'react';
import Img from '~/components/Img';
import { AnimatePresence, motion } from 'framer-motion';

import "./PageBg.css";
const VideoBg = lazy(() => import('~/components/VideoBg'));

const PageBg = ({ backgroundImage, style, videoSrc, isRoot, endOpacity, fadeBottomOpacity = 0 }, ...props) => {
    return (
        <AnimatePresence
            mode='wait'
        >
            <motion.div
                style={{ ...style, "--root-bg-bottom-fade-opacity": fadeBottomOpacity }}
                key={(Array.isArray(backgroundImage) ? backgroundImage[0]?.id : backgroundImage?.id) + "page-bg"}
                initial={{ opacity: 0 }}
                transition={{
                    opacity: {
                        duration: 0.3
                    },
                    y: {
                        duration: 1,
                    },
                    // duration: 0.8,
                    ease: [0.25, 0.46, 0.45, 0.94]
                }}
                animate={{ opacity: endOpacity }}
                exit={{
                    opacity: 0, transition: {
                        duration: 0.1,
                        ease: [0.25, 0.46, 0.45, 0.94]
                    }
                }}
                className={`page__bg ${isRoot ? "page__bg--root" : ""}`}

            >
                {videoSrc ? <VideoBg
                    src={videoSrc}
                // src="https://video-previews.elements.envatousercontent.com/files/0b0ea5ed-194d-4038-8ee9-f1135bf0da31/video_preview_h264.mp4"
                // src="https://video-previews.elements.envatousercontent.com/60473ce4-6a01-4efe-ae6b-e99ed73972eb/watermarked_preview/watermarked_preview.mp4"
                // src="https://video-previews.elements.envatousercontent.com/e75aee57-9660-4abf-91ce-d5301c8f7a78/watermarked_preview/watermarked_preview.mp4"
                // src="https://video-previews.elements.envatousercontent.com/h264-video-previews/d766d734-5c23-4f03-b6b9-dc22a5fa66eb/21192064.mp4"
                // src="https://video-previews.elements.envatousercontent.com/h264-video-previews/9719bbca-5747-4552-a988-201f2e8a5cde/21487648.mp4"
                // src="https://video-previews.elements.envatousercontent.com/aa16082b-e66f-49ba-a48a-2294e15ec16b/watermarked_preview/watermarked_preview.mp4"
                // src="https://video-previews.elements.envatousercontent.com/8d11ff60-5f7f-4c59-a981-db7faac5b0ee/watermarked_preview/watermarked_preview.mp4"
                // src="https://video-previews.elements.envatousercontent.com/d26410a0-25ab-4870-92f4-2eb6a44e2d0f/watermarked_preview/watermarked_preview.mp4"
                /> : <Img
                    {...props}
                    // style={{ "--root-bg-opacity": properties.rootBgOpacity || 0.7 }}
                    // className="root__bg"
                    // width={2560}
                    // minWidth={1000}
                    // height={1000}
                    // role="presentation"
                    // imgObj={properties.rootBg}
                    // decoding="async"
                    // fetchpriority="high"
                    // lazy={false}

                    className={`page__bg__img ${isRoot ? "page__bg__img--root" : ""}`}
                    width={2560}
                    // minWidth={1000}
                    height={1200}
                    role="presentation"
                    decoding="async"
                    fetchpriority="high"
                    imgObj={backgroundImage}
                    lazy={false}
                />}


            </motion.div>
        </AnimatePresence>
    );
};

export default PageBg;